import DisplayChart from '@/components/Charts/DisplayChart.vue';
import GaugeChart from '@/components/Charts/GaugeChart.vue';
import BarGraphChart from '@/components/Charts/BarGraphChart.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import StackedLineChart from '@/components/Charts/StackedLineChart.vue';
import RadarChart from '@/components/Charts/RadarChart.vue';
import TableChart from '@/components/Charts/TableChart.vue'
import BooleanChart from '@/components/Charts/BooleanChart.vue';

import EditDisplayChart from '@/views/Forms/EditDisplayChart.vue';
import EditGaugeChart from '@/views/Forms/EditGaugeChart.vue';
import EditBarGraphChart from '@/views/Forms/EditBarGraphChart.vue';
import EditPieChart from '@/views/Forms/EditPieChart.vue';
import EditStackedLineChart from '@/views/Forms/EditStackedLineChart.vue';
import EditRadarChart from '@/views/Forms/EditRadarChart.vue';
import EditTableChart from '@/views/Forms/EditTableChart.vue';
import EditBooleanChart from '@/views/Forms/EditBooleanChart.vue';

enum ChartType {
    GAUGE = 'GaugeChart',
    DISPLAY = 'DisplayChart',
    BAR_GRAPH = 'BarGraphChart',
    PIE_CHART = 'PieChart',
    STACKED_LINE = 'StackedLineChart',
    TABLE = 'TableChart',
    RADAR = 'RadarChart',
    BOOLEAN = 'BooleanChart'
}

const chartList = [
    {
        name: 'Display',
        type: ChartType.DISPLAY,
        chartComponent: DisplayChart,
        editComponent: EditDisplayChart,
        layoutMinW: 2,
        layoutMinH: 3
    },
    // {
    //     name: 'Booleano',
    //     type: ChartType.BOOLEAN,
    //     chartComponent: BooleanChart,
    //     editComponent: EditBooleanChart,
    //     layoutMinW: 2,
    //     layoutMinH: 3
    // },
    {
        name: 'Tabela',
        type: ChartType.TABLE,
        chartComponent: TableChart,
        editComponent: EditTableChart,
        layoutMinW: 2,
        layoutMinH: 6
    },
    {
        name: 'Medidor',
        type: ChartType.GAUGE,
        chartComponent: GaugeChart,
        editComponent: EditGaugeChart,
        layoutMinW: 2,
        layoutMinH: 6
    },
    {
        name: 'Barras',
        type: ChartType.BAR_GRAPH,
        chartComponent: BarGraphChart,
        editComponent: EditBarGraphChart,
        layoutMinW: 2,
        layoutMinH: 6
    },
    {
        name: 'Pizza',
        type: ChartType.PIE_CHART,
        chartComponent: PieChart,
        editComponent: EditPieChart,
        layoutMinW: 2,
        layoutMinH: 6
    },
    {
        name: 'Linhas',
        type: ChartType.STACKED_LINE,
        chartComponent: StackedLineChart,
        editComponent: EditStackedLineChart,
        layoutMinW: 4,
        layoutMinH: 6
    },
    {
        name: 'Radar',
        type: ChartType.RADAR,
        chartComponent: RadarChart,
        editComponent: EditRadarChart,
        layoutMinW: 2,
        layoutMinH: 6
    }
]

function getFirstChartType(): ChartType {
    return chartList[0].type
}

const getChartComponent = (name: string) => {
    return chartList.find(c => c.type === name)?.chartComponent
}

const getEditComponent = (name: string) => {
    return chartList.find(c => c.type === name)?.editComponent
}

const getChartLayoutMinW = (type: string) => {
    return chartList.find(c => c.type === type)?.layoutMinW
}

const getChartLayoutMinH = (type: string, nOfInputs: number) => {
    if (type === ChartType.DISPLAY && nOfInputs === 2) return 4
    if (type === ChartType.DISPLAY && nOfInputs === 3) return 6
    return chartList.find(c => c.type === type)?.layoutMinH
}

export { ChartType, chartList, 
    getFirstChartType, getChartComponent, getEditComponent,
    getChartLayoutMinW, getChartLayoutMinH }

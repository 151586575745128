import IStackedLineChartReport from "@/interfaces/IStackedLineChartReport";
import { jsPDF } from "jspdf";

export default function generatePdf(report: IStackedLineChartReport) {
    const timeCreated = new Date()
    const timeCreatedString = `${timeCreated.toLocaleDateString()} ${timeCreated.toLocaleTimeString()}`
    const dateFromAsString = `Início: ${report.dateFrom.toLocaleDateString()} ${report.dateFrom.toLocaleTimeString()}`
    const dateToAsString = `Fim: ${report.dateTo.toLocaleDateString()} ${report.dateTo.toLocaleTimeString()}`

    const pdf = new jsPDF();
    const pageHeight = pdf.internal.pageSize.getHeight()
    const pageWidth = pdf.internal.pageSize.getWidth()

    const font = 'helvetica'

    const lineHeight = 6
    const margin = 15
    const marginTop = 8

    let currentY = marginTop

    const newLine = () => {
        currentY += lineHeight
    }

    const newParagraph = () => {
        currentY += lineHeight * 3
    }

    const logo = document.getElementById('logo-tridium') as HTMLImageElement
    
    if (logo) {
        pdf.addImage(logo, 'PNG', margin, currentY, 37.5, 7.75)
    }

    newLine()
    newParagraph()

    pdf.setFont(font, 'bold')
    pdf.setFontSize(16)
    pdf.text(report.title.toUpperCase(), pageWidth/2, currentY, { align: "center" });

    if (report.subtitle) {
        newLine()
        pdf.setFont(font, 'normal')
        pdf.setFontSize(14)
        pdf.text(report.subtitle, pageWidth/2, currentY, {align: 'center'})
    }

    newLine()
    pdf.setFontSize(12)
    pdf.text(timeCreatedString, pageWidth/2, currentY, {align: 'center'})

    newParagraph()
    pdf.text(dateFromAsString, margin, currentY)
    pdf.text(dateToAsString, pageWidth-margin, currentY, { align: 'right' })

    newLine()
    const chartWith = pageWidth - (margin * 2)
    const chartHeight = calculateChartHeight(report.width, report.height, chartWith)

    if (report.chartImage) {
        pdf.addImage(report.chartImage, 'PNG', margin, currentY, chartWith, chartHeight)
        currentY += chartHeight
    }

    if (report.description) {
        newParagraph()
        pdf.setFont(font, 'bold')
        pdf.text('Descrição', margin, currentY)
        newLine()
        pdf.setFont(font, 'normal')
        pdf.text(report.description, margin, currentY)
    }
    
    if (report.observation) {
        newParagraph()
        pdf.setFont(font, 'bold')
        pdf.text('Observações', margin, currentY)
        newLine()
        pdf.setFont(font, 'normal')
        pdf.text(report.observation, margin, currentY)
    }

    // Write at the bottom
    currentY = pageHeight - marginTop - (lineHeight * 3)
    const underline = '________________________'
    pdf.text(underline, pageWidth/2, currentY, { align: 'center'})
    if (report.employeeName) {
        newLine()
        pdf.text(report.employeeName, pageWidth/2, currentY, { align: 'center'})
    }
    if (report.employeeJob) {
        newLine()
        pdf.text(report.employeeJob, pageWidth/2, currentY, { align: 'center'})
    }
    
    pdf.save(`relatorio-${timeCreated.getFullYear()}-${timeCreated.getMonth()}-${timeCreated.getDate()}.pdf`);
}

function calculateChartHeight(width: number, height: number, targetWidth: number): number {
    return targetWidth * height / width
}
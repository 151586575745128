import { Store } from "vuex"
import { State } from "."
import { REQUEST_DATA_HISTORY } from "./action-types"
import { REGISTER_DATA_HISTORY_REQUEST, REMOVE_DATAHISTORY, UNREGISTER_DATA_HISTORY_REQUEST } from "./mutation-types"

export const dataHistoryPlugin = (store: Store<State>) => {
    store.subscribe((mutation) => {
        if (mutation.type === REGISTER_DATA_HISTORY_REQUEST) {
            store.dispatch(REQUEST_DATA_HISTORY, mutation.payload)
        }
    })
    store.subscribe((mutation) => {
        if (mutation.type === UNREGISTER_DATA_HISTORY_REQUEST) {
            store.commit(REMOVE_DATAHISTORY, mutation.payload)
        }
    })
}
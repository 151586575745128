export enum NotificationType {
    SUCCESS, INFO, WARNING, ERROR
}

export function createNotification(type: NotificationType, text: string) {
    const notification = document.createElement('div')

    notification.innerText = text
    notification.classList.add('notification')
    notification.classList.add('mb-2')
    
    setNotificationType(type, notification)    
    addCloseButton(notification)

    showNotification(notification)
    setTimeout(() => closeNotification(notification), 5000)
}

function addCloseButton(notification: HTMLElement) {
    const closeButton = document.createElement('button')
    closeButton.classList.add('delete')

    notification.appendChild(closeButton)

    closeButton.onclick = () => closeNotification(notification)
}

function setNotificationType(type: NotificationType, notification: HTMLElement) {
    switch(type) {
        case NotificationType.SUCCESS:
            notification.classList.add('is-success')
            break
        case NotificationType.INFO:
            notification.classList.add('is-info')
            break
        case NotificationType.WARNING:
            notification.classList.add('is-warning')
            break
        case NotificationType.ERROR:
            notification.classList.add('is-danger')
            break
    }
}

function showNotification(notification: HTMLElement) {
    const notificationBox = document.getElementById('notification-box')
    notificationBox?.appendChild(notification)
}

function closeNotification(notification: HTMLElement) {
    const notificationBox = document.getElementById('notification-box')

    try {
        notificationBox?.removeChild(notification)
    } catch {
        return
    }
}
import { Module } from "vuex"
import { State } from ".."
import http from "@/http"
import { RESET, SET_AUTHORITIES, SET_USERNAME } from "../mutation-types"
import { LOGIN, LOGOUT, RESET_ALL } from "../action-types"
import router from "@/router"

export interface AuthState {
    username: string,
    authorities: string[]
}

const getDefaultState = () => {
    return {
        username: '',
        authorities: []
    }
}

export const auth: Module<AuthState, State> = {
    state: getDefaultState(),
    getters: {
        isAuthenticated(state): boolean {
            return !!state.username
        },
        isAdmin(state): boolean {
            return state.authorities.includes('ADMIN')
        }
    },
    mutations: {
        [RESET](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_USERNAME](state, username: string) {
            state.username = username
        },
        [SET_AUTHORITIES](state, autorities: string[]) {
            state.authorities = autorities
        }
    },
    actions: {
        [LOGIN]({commit}, user: FormData) {
            return http
                .post('/auth/login', user)
                .then(resp => {
                    commit(SET_USERNAME, user.get("username"))
                    commit(SET_AUTHORITIES, resp.data.user.authorities)
                    localStorage.setItem('token', resp.data.token)
                })
        },
        [LOGOUT]({dispatch}) {
            dispatch(RESET_ALL)
            localStorage.removeItem('token')
            router.push('/login')
        }
    }
}

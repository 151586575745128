enum Comparator {
    GREATER = 'GREATER',
    GREATEROREQUAL = 'GREATEROREQUAL',
    EQUAL = 'EQUAL',
    LESSER = 'LESSER',
    LESSEROREQUAL = 'LESSEROREQUAL'
}

const getComparators = (): Comparator[] => {
    return [
        Comparator.GREATER,
        Comparator.GREATEROREQUAL,
        Comparator.EQUAL,
        Comparator.LESSER,
        Comparator.LESSEROREQUAL
    ]
}

const getComparatorLabel = (comparator: Comparator): string => {
    switch (comparator) {
        case Comparator.GREATER:
            return 'Maior que'
        case Comparator.GREATEROREQUAL:
            return 'Maior ou igual a'
        case Comparator.EQUAL:
            return 'Igual a'
        case Comparator.LESSER:
            return 'Menor que'
        case Comparator.LESSEROREQUAL:
            return 'Menor ou igual a'
        default:
            return ''
    }
}

export { Comparator, getComparatorLabel, getComparators }

import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import DashBoard from '@/views/DashBoard.vue'
import ChartForm from '@/views/ChartForm.vue'
import LoginForm from '@/views/LoginForm.vue'
import UserControl from '@/views/UserControl.vue'
import AdminControl from '@/views/AdminControl.vue';
import AdminForm from '@/views/AdminForm.vue';
import CompanyForm from '@/views/CompanyForm.vue'
import UserForm from '@/views/UserForm.vue'
import { store } from '@/store';
import PasswordUpdateForm from '@/views/Forms/PasswordUpdateForm.vue';
import AlarmControl from '@/views/AlarmControl.vue';
import AlarmForm from '@/views/Forms/AlarmForm.vue';

const routes: RouteRecordRaw[] = [{
    path: '/',
    name: 'Index',
    component: DashBoard,
    meta: {requiresAuth: true}
},
{
    path: '/dashboards',
    name: 'Bem Vindo',
    component: DashBoard,
    meta: {requiresAuth: true}
},
{
    path: '/dashboards/:dashboardId',
    name: 'Dashboards',
    component: DashBoard,
    props: true,
    meta: {requiresAuth: true}
},
{
    path: '/dashboards/:dashboardId/graficos/novo',
    name: 'Novo gráfico',
    component: ChartForm,
    props: true,
    meta: {requiresAuth: true}
},
{
    path: '/dashboards/:dashboardId/graficos/:chartId',
    name: 'Editar gráfico',
    component: ChartForm,
    props: true,
    meta: {requiresAuth: true}
},
{
    path: '/alarmes',
    name: 'Alarmes',
    component: AlarmControl,
    meta: { requiresAuth: true }
},
{
    path: '/alarmes/novo',
    name: 'Novo Alarme',
    component: AlarmForm,
    meta: { requiresAuth: true }
},
{
    path: '/alarmes/:alarmId',
    name: 'Editar Alarme',
    component: AlarmForm,
    props: true,
    meta: { requiresAuth: true }
},
{
    path: '/alterar-senha',
    name: 'Alterar senha',
    component: PasswordUpdateForm,
    meta: { requiresAuth: true }
},
{
    path: '/login',
    name: 'Login',
    component: LoginForm,
    meta: {guest: true}
},
{
    path: '/cadastro',
    name: 'Controle de usuários',
    component: UserControl,
    meta: {admin: true}
},
{
    path: '/cadastro/admin',
    name: 'Controle de administradores',
    component: AdminControl,
    meta: {admin: true}
},
{
    path: '/cadastro/admin/novo',
    name: 'Novo administrador',
    component: AdminForm,
    props: true,
    meta: {admin: true}
},
{
    path: '/cadastro/admin/:userId',
    name: 'Editar administrador',
    component: AdminForm,
    props: true,
    meta: {admin: true}
},
{
    path: '/cadastro/empresa/nova',
    name: 'Nova empresa',
    component: CompanyForm,
    meta: {admin: true}
},
{
    path: '/cadastro/empresa/:companyId',
    name: 'Editar empresa',
    component: CompanyForm,
    props: true,
    meta: {admin: true}
},
{
    path: '/cadastro/empresa/:companyId/usuario/novo',
    name: 'Novo usuário',
    component: UserForm,
    props: true,
    meta: {admin: true}
},
{
    path: '/cadastro/empresa/:companyId/usuario/:userId',
    name: 'Editar usuário',
    component: UserForm,
    props: true,
    meta: {admin: true}
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title as string || 'Easy Tracking';
    next();
  });

router.beforeEach((to) => {
    if (to.matched.some(record => record.meta.requiresAuth) 
        && !store.getters.isAuthenticated) {
        return '/login'
    }
})

router.beforeEach((to) => {
    if (to.matched.some(record => record.meta.guest) 
        && store.getters.isAuthenticated) {
        return false
    }
})

router.beforeEach((to) => {
    if (to.matched.some(record => record.meta.admin) 
        && store.getters.isAuthenticated
        && !store.getters.isAdmin) {
        return false
    }
})

export default router
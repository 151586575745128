enum DataType {
    INTEGER = 'i',
    UNSIGNED_INT = 'ui',
    DOUBLE_INT = 'd',
    UNSIGNED_DOUBLE_INT = 'ud',
    REAL = 'r',
    BIT = 'b',
    STRING = 's',
    STATE_STRING = 'es',
    STATE_INT = 'ei'
}

const getAllDataTypes = (): DataType[] => {
    return [DataType.INTEGER, DataType.UNSIGNED_INT, DataType.DOUBLE_INT, 
        DataType.UNSIGNED_DOUBLE_INT, DataType.REAL, DataType.BIT,
        DataType.STRING, DataType.STATE_STRING, DataType.STATE_INT]
}

const getNumericDataTypes = (): DataType[] => {
    return [DataType.INTEGER, DataType.UNSIGNED_INT, DataType.DOUBLE_INT, 
        DataType.UNSIGNED_DOUBLE_INT, DataType.REAL, DataType.STATE_INT]
}

const getNumericDataTypesWithoutState = (): DataType[] => {
    return [DataType.INTEGER, DataType.UNSIGNED_INT, DataType.DOUBLE_INT, 
        DataType.UNSIGNED_DOUBLE_INT, DataType.REAL]
}

const getStringDataTypes = (): DataType[] => {
    return [DataType.STRING, DataType.STATE_STRING]
}

const identifyDataTypeFromVariableName = (variableName: string): DataType | undefined => {
    return getAllDataTypes().find(dataType => variableName.startsWith(dataType))
}

const getVariableNameWithoutPrefix = (variableName: string): string => {
    const length = identifyDataTypeFromVariableName(variableName)?.length || 0
    return variableName.substring(length)
}

export { 
    DataType, getAllDataTypes, getNumericDataTypes, 
    getNumericDataTypesWithoutState, getStringDataTypes,
    getVariableNameWithoutPrefix 
}
